import { env } from '@/lib/env';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const SERVICE = 'jobreel-website';

let isDatadogInitialized = false;

export function initializeDatadog() {
  if (typeof window === 'undefined' || isDatadogInitialized) return;

  datadogRum.init({
    applicationId: env.dd.applicationId,
    clientToken: env.dd.clientToken,
    site: env.dd.site,
    service: SERVICE,
    env: env.stage,
    version: env.version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    // Specify URLs to propagate trace headers for connection between RUM and backend trace
    // allowedTracingUrls: [
    //   { match: 'https://example.com/api/', propagatorTypes: ['tracecontext'] },
    // ],
  });

  datadogLogs.init({
    clientToken: env.dd.clientToken,
    site: env.dd.site,
    env: env.stage,
    service: SERVICE,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    version: env.version,
  });

  isDatadogInitialized = true;
}

export { datadogRum, datadogLogs };
