import { CandidateReasearchStatus } from '@/lib/utils/enums/CandidateReasearchStatus';
import { PositionType } from '@/lib/utils/enums/PositionType';
import jobreelApi from '@/lib/utils/jobreel-api';
import {
  getBearerToken,
  getCurrentProfile,
  getTokenPayload,
  storeCurrentProfile,
} from '@/lib/utils/functions/authentication';
import { ServerResponse } from '@/lib/types';
import {
  UpsertCertificationDto,
  UpsertExperienceDto,
} from '@/modules/profile/profile.dto';
import {
  Profile,
  ProfileCertification,
  ProfileExperience,
} from '@/modules/profile/profile.model';

export const Profile_Create = async ({
  open_to,
  interests,
}: {
  open_to: PositionType[];
  interests: any[];
}) => {
  const { userId } = getTokenPayload();

  const { data } = await jobreelApi.post<ServerResponse<Profile>>(
    '/v1/profiles',
    {
      is_public: false,
      interests,
      open_to,
      current_status: CandidateReasearchStatus.Active,
      user: userId,
    },
    { headers: { Authorization: getBearerToken() } }
  );

  storeCurrentProfile(data.data.hash);

  return data.data;
};

export const Profile_Get = async () => {
  const { data } = await jobreelApi.get<ServerResponse<Profile>>(
    '/v1/profiles/findForUser',
    {
      headers: { Authorization: getBearerToken() },
    }
  );
  return data.data;
};

export const Profile_GetByHash = async (hash: string) => {
  const { data } = await jobreelApi.get<ServerResponse<Profile>>(
    `/v1/profiles/${hash}`,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );
  return data.data;
};

export const Profile_Update = async (
  profileHash: string,
  payload: {
    open_to?: PositionType[];
    interests?: string[];
    is_public?: boolean;
  }
): Promise<Profile> => {
  const { data } = await jobreelApi.patch<ServerResponse<Profile>>(
    '/v1/profiles/' + profileHash,
    payload,
    { headers: { Authorization: getBearerToken() } }
  );
  return data.data;
};

export const Experience_Create = async (
  payload: UpsertExperienceDto
): Promise<ProfileExperience> => {
  const profile = await Profile_Get();

  const { data } = await jobreelApi.post<ServerResponse<ProfileExperience>>(
    `/v1/profiles/${profile.hash}/experiences`,
    payload,
    {
      headers: { Authorization: getBearerToken() },
    }
  );

  return data.data;
};

export const Experience_ListCurrent = async (): Promise<
  ProfileExperience[]
> => {
  const profile = await Profile_Get();

  const { data } = await jobreelApi.get<ServerResponse<ProfileExperience[]>>(
    `/v1/profiles/${profile.hash}/experiences`,
    {
      headers: { Authorization: getBearerToken() },
    }
  );

  return data.data;
};

export const Experience_Get = async (
  hash: string
): Promise<ProfileExperience> => {
  const profile = await Profile_Get();

  const { data } = await jobreelApi.get<ServerResponse<ProfileExperience>>(
    `/v1/profiles/${profile.hash}/experiences/${hash}`,
    { headers: { Authorization: getBearerToken() } }
  );

  return data.data;
};

export const Experience_Update = async (
  hash: string,
  payload: UpsertExperienceDto
): Promise<ProfileExperience> => {
  const profile = await Profile_Get();

  const { data } = await jobreelApi.patch<ServerResponse<ProfileExperience>>(
    `/v1/profiles/${profile.hash}/experiences/${hash}`,
    {
      ...payload,
      start_date: payload.start_date ? payload.start_date : undefined,
      end_date: payload.end_date ? payload.end_date : undefined,
    },
    { headers: { Authorization: getBearerToken() } }
  );

  return data.data;
};

export const Experience_Delete = async (hash: string): Promise<boolean> => {
  const profile = await Profile_Get();

  const { status } = await jobreelApi.delete<ServerResponse<ProfileExperience>>(
    `/v1/profiles/${profile.hash}/experiences/${hash}`,
    {
      headers: { Authorization: getBearerToken() },
    }
  );

  return status === 204;
};

export const Certification_Create = async (
  payload: UpsertCertificationDto
): Promise<ProfileCertification> => {
  const { data } = await jobreelApi.post<ServerResponse<ProfileCertification>>(
    `/v1/profiles/${getCurrentProfile()}/certifications`,
    payload,
    {
      headers: { Authorization: getBearerToken() },
    }
  );
  return data.data;
};

export const Certification_Get = async (
  hash: string
): Promise<ProfileCertification> => {
  const { data } = await jobreelApi.get<ServerResponse<ProfileCertification>>(
    `/v1/profiles/${getCurrentProfile()}/certifications/${hash}`,
    {
      headers: { Authorization: getBearerToken() },
    }
  );

  return data.data;
};

export const Certification_ListCurrent = async () => {
  const profile = await Profile_Get();

  const { data } = await jobreelApi.get<ServerResponse<ProfileCertification[]>>(
    `/v1/profiles/${profile.hash}/certifications`,
    {
      headers: {
        Authorization: getBearerToken(),
      },
    }
  );

  return data.data;
};

export const Certification_Update = async (
  hash: string,
  payload: UpsertCertificationDto
): Promise<ProfileCertification> => {
  const profile = await Profile_Get();

  const { data } = await jobreelApi.patch<ServerResponse<ProfileCertification>>(
    `/v1/profiles/${profile.hash}/certifications/${hash}`,
    {
      ...payload,
      issued_at:
        !!payload.issued_at ?
          new Date(payload.issued_at).toISOString()
        : undefined,
      expiring_on:
        !!payload.expiring_on ?
          new Date(payload.expiring_on).toISOString()
        : undefined,
      url: payload.url ? payload.url : undefined,
    },
    { headers: { Authorization: getBearerToken() } }
  );

  return data.data;
};

export const Certification_Delete = async (hash: string): Promise<boolean> => {
  const profile = await Profile_Get();

  const { status } = await jobreelApi.delete<ServerResponse<ProfileExperience>>(
    `/v1/profiles/${profile.hash}/certifications/${hash}`,
    {
      headers: { Authorization: getBearerToken() },
    }
  );

  return status === 204;
};
