export enum Keys {
  AuthenticationToken = '_jr_auth_token',
  RefreshToken = '_jr_refresh_token',
  AuthenticationData = '_jr_auth_data',
  AuthenticationUser = '_jr_auth_user',
  CurrentProfile = '_jr_profile',
  CurrentBusiness = '_jr_business',
  Mode = '_jr_mode',
  Overlay = '_jr_mode',
  CurrentLink = '_jr_current_link',
  CurrentJob = '_jr_current_job',
  JobAddresses = '_jr_job_addresses',
  CurrentUser = '_jr_current_user',
  EEOAlert = 'jr_eeo-alert',
}
