export const env = {
  baseApiUrl: process.env.NEXT_PUBLIC_API_URL as string,
  dd: {
    clientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN as string,
    applicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID as string,
    site: process.env.NEXT_PUBLIC_DD_SITE as string,
  },
  stage: process.env.NEXT_PUBLIC_DD_STAGE as string,
  version: process.env.NEXT_PUBLIC_VERSION as string,
};
